import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Layout, Hero } from "../components/";

const IndexPage = () => {
    const data = useStaticQuery(graphql`
        query IndexQuery {
            directus {
                index {
                    content
                }
            }
        }
    `);

    return (
        <>
            <Layout>
                <Hero />
                <div
                    className="mx-auto my-10 prose lg:prose-lg max-w-100ch"
                    dangerouslySetInnerHTML={{
                        __html: data.directus.index.content,
                    }}
                ></div>
            </Layout>
        </>
    );
};

export default IndexPage;
